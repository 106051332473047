import Layout from '../components/layout';
import SEO from '../components/seo';
import React from 'react';
import PrivacyContainer from '../components/PrivacyContainer/privacyContainer';

const PrivacyPage = ({ location }) => {
  return (
    <Layout location={location} headerIsStatic>
      <SEO title="Политика конфиденциальности" description="10 лет назад, компания молодых и трудолюбивых людей решила объединиться и создать доставку с незамысловатым названием 'Хочу Суши'" />
      <PrivacyContainer />
    </Layout>
  );
};

export default PrivacyPage;
